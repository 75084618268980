import Loader from 'react-spinners/PropagateLoader';
import { isBaNorte_ } from 'utils/contants';

const Loading = () => (
  <div
    className="mt-5"
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Loader size={15} color={isBaNorte_ ? "#E8002A" :"#007bff"} loading />
  </div>
);

export default Loading;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Chapter, Guide, Lesson } from 'interfaces/guide.interface';
import { SidebarItem } from 'modules/classroom/components/navbar/SidebarItem';
import { CertificateButton } from 'modules/classroom/components/navbar/Certificate';
import { useTranslation } from 'react-i18next';
import { sortItems } from 'utils/talents.util';
import { isBaNorte_ } from 'utils/contants';

interface SidebarProps {
  guide: Guide;
}
const createArray = (cantidad: number): number[] => {
  return Array.from({ length: cantidad }, (_, i) => i);
};

export function Sidebar({ guide }: SidebarProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  const { topics = [], hasCertificate = false } = guide;

  return (
    <Accordion defaultIndex={createArray(topics.length + 2)} allowMultiple allowToggle>
      {topics?.map((topic) => (
        <Box key={topic.id} py={2}>
          <Box px={4}>
            <Heading  color={isBaNorte_?'red':'brand'} as="h6" size="sm">
              {topic.title}
            </Heading>
            <Text fontSize="sm" color="gray">
              {topic.description}
            </Text>
          </Box>
          {sortItems<Chapter>(topic.chapters)?.map((chapter) => (
            <AccordionItem key={chapter.id} py={1}>
              <AccordionButton border="1px solid" borderColor="gray.300">
                <Box flex="1" textAlign="left">
                  <Text>{chapter.title}</Text>
                  <Text as="span" fontSize="sm" color="gray">
                    {chapter.description}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel as={Stack} spacing={2} py={1}>
                {sortItems<Lesson>(chapter.lessons).map((lesson) => (
                  <SidebarItem key={lesson.id} lesson={lesson} />
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Box>
      ))}
      {hasCertificate && (
        <AccordionItem>
          <AccordionButton>
            <Text flex="1" textAlign="left">
              {t('end')}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CertificateButton />
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  );
}

import { saveAs } from 'file-saver';
import axios from 'axios';

import { Button, ButtonProps, useToast } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import useGetUserId from 'hooks/api/useGetUserId';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { isBaNorte_ } from 'utils/contants'; 

export function DownloadCertificate({ children, ...props }: ButtonProps) {
  const [loading, setLoading] = useState(false);
  const { id: activityId } = useParams<{ id: string }>();
  const { guide } = useGuideContext();
  const userId = useGetUserId();
  const toast = useToast();

  const downloadCertificate = async () => {
    setLoading(true);

    try {
      const response = await apiCall(`downloadCertificate/${userId}/${activityId}`, null, 'GET', 'blob');
      const content = response.data;
      saveAs(content, 'Certificate.pdf');
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;

        if (status === 422) {
          toast({
            status: 'warning',
            title: 'Falta que completes datos en tu perfil',
          });
          return;
        }
      }

      toast({
        status: 'error',
        title: 'Hubo un error',
        description: 'Intente de nuevo mas tarde',
      });
    } finally {
      setLoading(false);
    }
  };

  if (!guide?.hasCertificate) return null;

  return (
    <Button  colorScheme={isBaNorte_?'red':'brand'} {...props} isLoading={loading} onClick={downloadCertificate}>
      {children}
    </Button>
  );
}

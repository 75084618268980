import { useState } from 'react';
import { handleBlur, handleFocus } from 'utils/contants';

const Text = ({ addAnswers }) => {
  const [text, setText] = useState('');

  const change = (e) => {
    setText(e.target.value);
    addAnswers(e.target.value);
  };

  return <input type="text" onFocus={handleFocus}
    onBlur={handleBlur} className="form-control" onChange={(e) => change(e)} value={text} />;
};

export default Text;

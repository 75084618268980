import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import AnonymousUser from 'assets/anonymous_user.jpg';
import config from 'config';
import { Talent } from 'interfaces';
import { BsPatchCheckFill } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { LuMail, LuMapPin, LuScrollText } from 'react-icons/lu';
import { MdWorkOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { isBaNorte_ } from 'utils/contants';
import { STORAGE_URL } from 'utils/talents.util';

export function Card({ talent }: { talent: Talent }) {
  const { image, id, title, name, surname, state, email, skills, volunteeringFields } = talent;

  return (
    <Flex bg="white" rounded="xl" p={4} direction="column" justify="space-between">
      <HStack gap={4} align="start" flexDir={{ base: 'column', sm: 'row', md: 'column', lg: 'row' }}>
        <HStack flex="1" spacing={4} align="start">
          <Image
            borderRadius="full"
            boxSize="50px"
            src={image ? `${STORAGE_URL}/${image}` : AnonymousUser}
            alt={`${name} ${surname}`}
          />
          <Stack align="start">
            <div>
              <Heading size="md" fontWeight="medium">
                {title}
              </Heading>
              <Heading as="h1" size="sm" fontWeight="light">
                {name} {surname}
              </Heading>
            </div>
            <List fontWeight="light" fontSize="sm">
              {!config.has_country && (
                <ListItem color="gray.500">
                  <ListIcon as={LuMapPin} />
                  {state}
                  {state && ', '}
                  Argentina
                </ListItem>
              )}
              <ListItem>
                <ListIcon as={LuMail} />
                {email}
              </ListItem>
            </List>
          </Stack>
        </HStack>
        <Stack flex="1" align="start">
          <Flex align="center" gap={4}>
            <Heading size="sm" fontWeight="medium" m={0}>
              Habilidades
            </Heading>
            <Icon as={BsPatchCheckFill} fontSize="md" color={'green'} />
          </Flex>
          <Wrap>
            {skills.map((skill) => (
              <WrapItem key={skill.id}>
                <Text fontSize="md" fontWeight="light">
                  {skill.title}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </Stack>
      </HStack>
      <HStack justify="space-between" gap={4} mt={6} flexWrap="wrap">
        {volunteeringFields?.portfolio_url && (
          <Button
            as="a"
            rounded="full"
            variant="outline"
            size="sm"
            colorScheme={isBaNorte_ ? 'red' : 'linkedin'}
            href={volunteeringFields.portfolio_url}
            target="_blank"
            rel="noreferrer"
            leftIcon={<MdWorkOutline size={20} />}
          >
            Portfolio
          </Button>
        )}
        {!isBaNorte_ && volunteeringFields?.linkedin_url && (
          <Button
            as="a"
            rounded="full"
            variant="outline"
            size="sm"
            colorScheme="linkedin"
            href={volunteeringFields.linkedin_url}
            target="_blank"
            rel="noreferrer"
            leftIcon={<FaLinkedinIn size={20} />}
          >
            LinkedIn
          </Button>
        )}
        {volunteeringFields?.curriculum_url && (
          <Button
            as="a"
            rounded="full"
            variant="outline"
            size="sm"
            colorScheme={isBaNorte_ ? 'red' : 'linkedin'}
            href={volunteeringFields.curriculum_url}
            target="_blank"
            rel="noreferrer"
            leftIcon={<LuScrollText size={20} />}
          >
            CV
          </Button>
        )}
        <Button
          as={Link}
          to={`/profile/${id}`}
          rounded="full"
          size="sm"
          ml="auto"
          bg={isBaNorte_ ? '#E8002A' : '#2DAAE1'}
          _hover={
            isBaNorte_
              ? { color: 'white', textDecoration: 'none', bg: 'red.400' }
              : { color: 'white', textDecoration: 'none', bg: 'brand.600' }
          }
        >
          Sobre Mi
        </Button>
      </HStack>
    </Flex>
  );
}

import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import { apiCall } from 'crud/api.crud';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import statesCessiJSON from 'assets/states-cessi.json';
import countriesJSON from 'components/pages/Private/Profile/MyPersonalData/hook/countries.json';
import config from 'config';
import { Filter } from 'modules/talent/pages/Talents';
import { isCessi } from 'utils/talents.util';
import { isBaNorte_ } from 'utils/contants';

interface Option {
  value: string;
  label: string;
}

interface State extends Option {
  localidades?: Option[];
}

const COUNTRY = isBaNorte_ ? 'México' : 'Argentina';

export function Filters({
  filter,
  setFilter,
}: {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}) {
  const [states, setStates] = useState<State[]>([]);
  const [localities, setLocalities] = useState<Option[]>([]);
  const [skills, setSkills] = useState<Option[]>([]);
  const [state, setState] = useState<State>();
  const [locality, setLocality] = useState<Option>();
  const [selectedCountry, setSelectedCountry] = useState<Option>();

  useEffect(() => {
    if (isCessi) {
      setStates(statesCessiJSON);
    } else {
      apiCall(`entity/states/${COUNTRY}`, null, 'GET').then(({ data }) => {
        if (!data.data) return;
        setStates(data.data.map((s) => ({ value: s.id, label: s.title })));
      });
    }
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/skills`, null, 'GET').then(({ data }) => {
      const skills = data.data.map((s) => ({ value: s.id, label: s.title }));
      setSkills([{ value: 0, label: 'Verificados' }, ...skills]);
    });
  }, []);

  return (
    <HStack justify="space-between" flexDirection={['column', null, 'row']} gap={6}>
      <FormControl>
        <FormLabel htmlFor="name">Nombre</FormLabel>
        <Input
          variant="filled"
          bg="white"
          name="name"
          onChange={(e) => {
            const trimmedValue = e.target.value.trim();
            setFilter({ ...filter, name: trimmedValue });
          }}
          placeholder="Nombre"
        />
      </FormControl>
      {config.has_country && (
        <FormControl>
          <FormLabel htmlFor="country">País</FormLabel>
          <Select
            id="country"
            name="countries"
            options={countriesJSON}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder="Selecciona un país"
            value={selectedCountry}
            onChange={(input) => {
              setSelectedCountry(input);
              setFilter((prev) => ({
                ...prev,
                countries: input ? [String(input.value)] : [],
              }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      {!config.has_country && (
        <FormControl>
          <FormLabel htmlFor="state">{isBaNorte_ ? 'Estado' : 'Provincia'}</FormLabel>
          <Select
            id="state"
            name="states"
            options={states}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder={isBaNorte_ ? 'Selecciona un Estado' : 'Selecciona una provincia'}
            isLoading={!states}
            onChange={(data: State) => {
              setState(data);
              if (isCessi) {
                setLocality(undefined);
                setLocalities(data?.localidades || []);
              }
              setFilter((prev) => ({ ...prev, states: data ? [data.value] : [], localities: [] }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      {isCessi && (
        <FormControl>
          <FormLabel htmlFor="localities">Localidad</FormLabel>
          <Select
            id="localities"
            name="localities"
            options={localities}
            noOptionsMessage={() => 'No hay opciones'}
            placeholder={!state ? 'Selecciona una provincia' : 'Seleciona una Localidad'}
            isDisabled={!state}
            value={locality}
            onChange={(data) => {
              setLocality(data);
              setFilter((prev) => ({ ...prev, localities: data ? [data.value] : [] }));
            }}
            cacheOptions
            isClearable
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel htmlFor="skill">{isBaNorte_ ? 'Sedes' : 'Habilidades'}</FormLabel>
        <Select
          id="skill"
          isMulti
          name="skills"
          noOptionsMessage={() => 'No hay opciones'}
          placeholder="Seleccion Multiple..."
          isClearable
          options={skills}
          onChange={(values) => {
            setFilter((prev) => ({ ...prev, skills: values?.map((v) => v.value) || [] }));
          }}
        />
      </FormControl>
    </HStack>
  );
}

import {
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import AnonymousUser from 'assets/anonymous_user.jpg';
import { apiCall } from 'crud/api.crud';
import { Talent } from 'interfaces';
import { Answers } from 'modules/talent/components/Answers';
import { Comments } from 'modules/talent/components/Comments';
import { Likes } from 'modules/talent/components/Likes';
import { useEffect, useState } from 'react';
import { BsArrowLeft, BsPatchCheckFill } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { LuMail, LuMapPin, LuScrollText } from 'react-icons/lu';
import { MdWorkOutline } from 'react-icons/md';
import { useHistory, useParams } from 'react-router';
import { isBaNorte_ } from 'utils/contants';

const STORAGE_URL =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_API_URL}/images`
    : 'https://app.fonselp.com/storage/images';

export default function ProfilePage() {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<Talent | null>(null);
  const history = useHistory();

  useEffect(() => {
    apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/talents/${id}`, null, 'GET').then(({ data }) =>
      setUser(data.data),
    );
    return () => {
      setUser(null);
    };
  }, [id]);

  if (!user) return null;

  const { volunteeringFields } = user;

  return (
    <Stack px={8} spacing={12}>
      <Flex justify="space-between">
        <IconButton colorScheme={isBaNorte_?'red':'brand'} aria-label="back page"  icon={<BsArrowLeft />} onClick={history.goBack} />
        <ButtonGroup>
          {volunteeringFields?.portfolio_url && (
            <Button
              as="a"
              rounded="full"
              href={volunteeringFields.portfolio_url}
              colorScheme={isBaNorte_ ? 'red' : 'linkedin'}
              target="_blank"
              rel="noreferrer"            
              variant="outline"              
              _hover={{ color: 'white', textDecoration: 'none', bg: 'brand.600' }}
              leftIcon={<MdWorkOutline size={20} />}
            >
              Portfolio
            </Button>
          )}
          {!isBaNorte_ && volunteeringFields?.linkedin_url && (
            <Button
              as="a"
              rounded="full"
              href={volunteeringFields.linkedin_url}
              target="_blank"
              variant="outline"
              rel="noreferrer"
              colorScheme='linkedin'
              _hover={{ color: 'white', textDecoration: 'none', bg: 'brand.600' }}
              leftIcon={<FaLinkedinIn size={20} />}
            >
              LinkedIn
            </Button>
          )}
          {volunteeringFields?.curriculum_url && (
            <Button
              as="a"
              rounded="full"
              href={volunteeringFields.curriculum_url}
              target="_blank"
              variant="outline"
              rel="noreferrer"
              colorScheme={isBaNorte_ ? 'red' : 'linkedin'}
              _hover={{ color: 'white', textDecoration: 'none', bg: 'brand.600' }}
              leftIcon={<LuScrollText size={20} />}
            >
              CV
            </Button>
          )}
        </ButtonGroup>
      </Flex>
      <Grid
        templateColumns={{
          base: '1fr',
          xl: 'repeat(3, 1fr)',
        }}
        gap={8}
      >
        <GridItem colSpan={2}>
          <HStack gap={4} flexDirection={['column', null, 'row']} align="start">
            <Image
              borderRadius="full"
              boxSize="150px"
              src={user.image ? `${STORAGE_URL}/${user.image}` : AnonymousUser}
              alt={`${user.name} ${user.surname}`}
            />
            <Stack flex="1">
              <div>
                <Heading size="lg" fontWeight="medium">
                  {user.title}
                </Heading>
                <Heading as="h1" size="lg" fontWeight="light">
                  {user.name} {user.surname}
                </Heading>
              </div>
              <List fontWeight="light" fontSize="lg">
                <ListItem color="gray.500">
                  <ListIcon as={LuMapPin} />
                  {user.state}
                  {user.state && ', '}
                  Argentina
                </ListItem>
                <ListItem>
                  <ListIcon as={LuMail} />
                  {user.email}
                </ListItem>
              </List>
            </Stack>
            <Stack flex="1">
              <Flex align="center" gap={2}>
                <Heading size="lg"  fontWeight="medium">
                  Habilidades
                </Heading>
                <Icon as={BsPatchCheckFill} fontSize="xl"  color="green" />
              </Flex>
              <Wrap>
                {user.skills.map((skill) => (
                  <WrapItem key={skill.id}>
                    <Text fontSize="xl" fontWeight="light">
                      {skill.title}
                    </Text>
                  </WrapItem>
                ))}
              </Wrap>
            </Stack>
          </HStack>

          <Heading size="lg" mt={12} mb={4} fontWeight="medium">
            Sobre Mi
          </Heading>

          <Text>{user.short_description}</Text>

          <Answers answers={user.answers ?? []} />
        </GridItem>
        <GridItem>
          <Flex justify="space-between" bg="white" rounded="xl" px={4} py={2} mb={4}>
            <Heading size="lg" fontWeight="medium">
              Recomendaciones
            </Heading>
            <Likes likes={user.likes} liked={user.liked} />
          </Flex>

          <Comments comments={user.comments} />
        </GridItem>
      </Grid>
    </Stack>
  );
}

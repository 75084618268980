import { RiArrowLeftLine } from 'react-icons/ri';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Button, Flex, Grid, GridItem, Link, Stack } from '@chakra-ui/react';

import { MobileSidebar } from 'modules/classroom/components/navbar/MobileSidebar';
import { Sidebar } from 'modules/classroom/components/navbar/Sidebar';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useTranslation } from 'react-i18next';
import {isBaNorte_} from 'utils/contants'
interface GuideLayoutProps {
  children: React.ReactNode;
}

export function GuideLayout({ children }: GuideLayoutProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const { guide } = useGuideContext();
  const { id: activityId } = useParams<{ id: string }>();

  return (
    <Box
      pt={{
        base: 8,
        lg: 0,
      }}
    >
      <Button
        as={RouterLink}
        to={`/detail/${activityId}?tab=guide`}
        size="sm"
      colorScheme={isBaNorte_ ? "red": "brand"}
        variant="ghost"
        leftIcon={<RiArrowLeftLine />}
        ml={2}
      >
        {t('back')}
      </Button>
      <Stack>
        <Flex justify="space-between" align="center" p={4}>
          <Link
            as={RouterLink}
            to={`/detail/${activityId}?tab=guide`}
            _hover={{
              color: isBaNorte_ ?'red.700':'brand.500',
            }}
            verticalAlign="top"
            fontSize={{
              base: '2xl',
              md: '3xl',
            }}
            fontWeight="semibold"
          >
            {guide.title}
          </Link>
          <MobileSidebar guide={guide} />
        </Flex>
        <Grid templateColumns="repeat(7,1fr)" minH="100vh">
          <GridItem
            colSpan={2}
            p={2}
            position="sticky"
            top={0}
            alignSelf="flex-start"
            height="100vh"
            overflowY="scroll"
            display={{
              base: 'none',
              lg: 'block',
            }}
          >
            <Sidebar guide={guide} />
          </GridItem>
          <GridItem
            colSpan={{
              base: 7,
              lg: 5,
            }}
            bgColor="white"
          >
            {children}
          </GridItem>
        </Grid>
      </Stack>
    </Box>
  );
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiCall } from '../../../../../crud/api.crud';
import useStore from '../../../../../store/zustand/store';
import { ButtonPrimary } from '../../../../molecules/Button';
import styled from 'styled-components';
import { MdClose, MdCheck, MdPendingActions } from 'react-icons/md';
import { isBaNorte_ } from 'utils/contants';

const ValidateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span.challenge {
    min-width: 150px;
    margin-right: 1rem;
  }
`;

const MIN_SCORE = 7;

const Skills = () => {
  const { t } = useTranslation();

  const user = useStore((state) => state.user);

  const userSkills = useStore((state) => state.user.skills);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [skills, setSkills] = useState([]);
  const [skillsSelected, setSkillsSelected] = useState(user.skills ? user.skills.map((s) => s.id) : []);
  const updateUser = useStore((state) => state.updateUser);

  useEffect(() => {
    const getSkills = async () => {
      const { data } = await apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/skills`, null, 'GET');
      const skills = data.data.map((skill) => {
        const userSkill = userSkills.find((s) => s.id === skill.id);

        if (userSkill) {
          skill.evaluation = userSkill.evaluation;
        }
        return skill;
      });
      setSkills(skills);
    };

    getSkills();

    return () => {
      setSkills([]);
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setShowMessage(true);
      const successTimer = setTimeout(() => {
        setShowMessage(false);
        setIsSuccess(false);
      }, 2000);

      return () => clearTimeout(successTimer);
    }

    if (isError) {
      setShowMessage(true);
      const errorTimer = setTimeout(() => {
        setShowMessage(false);
        setIsError(false);
      }, 2000);

      return () => clearTimeout(errorTimer);
    }
  }, [isSuccess, isError]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const { data } = await apiCall('profile/skills', { id: user.id, skills: skillsSelected }, 'POST');
      updateUser(data?.data);
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = ({ target }) => {
    const { checked, value } = target;
    if (checked) {
      setSkillsSelected([...skillsSelected, Number(value)]);
    } else {
      setSkillsSelected(skillsSelected.filter((s) => s !== Number(value)));
    }
  };

  return (
    <form className="container mt-4" onSubmit={onSubmit}>
      <div className="card shadow  bg-white rounded">
        <div className="card-header d-flex justify-content-between">
          {isBaNorte_ && <div>Sedes</div>}
          {!isBaNorte_ && <><div>Habilidades</div>
          {!!skills.length && (
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex mr-3">
                {' '}
                <div className="text-primary mr-1">
                  <MdPendingActions />
                </div>
                <p className="m-0">Pendiente de validación</p>
              </div>
              <div className="d-flex mr-3">
                {' '}
                <div className="text-success mr-1">
                  <MdCheck />
                </div>
                <p className="m-0">Validado</p>
              </div>
              <div className="d-flex mr-3">
                {' '}
                <div className="text-danger mr-1">
                  <MdClose />
                </div>
                <p className="m-0">Rechazado</p>
              </div>
            </div>
          )}</>}
        </div>

        <div className="card-block">
          <div className="card-body">
            <div className="row p-2">
              {skills.map(({ title, id, url, evaluation }) => (
                <div
                  className="d-flex col-12 col-md-6 col-lg-4 col-xl-3 bg-white justify-content-between align-items-center pl-4 mb-2"
                  key={`skill-${id}`}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`skill-${title}`}
                    value={id}
                    defaultChecked={skillsSelected.some((s) => s === id)}
                    onChange={handleChange}
                  />
                  <label className="form-check-label " htmlFor={`skill-${title}`}>
                    {title}
                  </label>
                  {url && (
                    <div className="d-flex">
                      <div className="mr-3 h5">
                        {evaluation && evaluation >= MIN_SCORE && (
                          <div className="text-success">
                            <MdCheck />
                          </div>
                        )}
                        {!evaluation && (
                          <div className="text-primary">
                            <MdPendingActions />
                          </div>
                        )}
                        {evaluation && evaluation < MIN_SCORE && (
                          <div className="text-danger">
                            <MdClose />
                          </div>
                        )}
                      </div>
                      {(!evaluation || evaluation < MIN_SCORE) && (
                        <ValidateContainer>
                          <div>
                            <a href={url} target="_blank" rel="noreferrer" className="btn btn-primary btn-sm">
                              Validar
                            </a>
                          </div>
                        </ValidateContainer>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {(!skills.length && !isBaNorte_ ) && <p>No hay Habilidades disponibles... </p>}
              {!skills.length && isBaNorte_  && <p>No hay sedes disponibles...</p>}
            </div>
            {showMessage && isSuccess && (
              <div className="alert alert-success w-80" role="alert">
                {t('profile.extra_msg2')}
              </div>
            )}
            {showMessage && isError && (
              <div className="alert alert-warning" role="alert">
                {t('profile.extra_msg1')}
              </div>
            )}
          </div>
        </div>
        <div className="card-footer">
          <ButtonPrimary type="submit" disabled={isSubmitting || !skills.length}>
            {isSubmitting ? t('button.button_msg6') : t('button.button_msg7')}
          </ButtonPrimary>
        </div>
      </div>
    </form>
  );
};

export default Skills;

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Quiz } from 'modules/classroom/components/quiz/Quiz';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useTranslation } from 'react-i18next';
import { isBaNorte_ } from 'utils/contants';

interface Params {
  quizId: string;
  id: string;
}

export function QuizPage() {
  const { guide } = useGuideContext();
  const { topics = [] } = guide;
  const { quizId } = useParams<Params>();

  const quiz = useMemo(() => {
    const lessons = topics?.flatMap(({ chapters }) => chapters).flatMap(({ lessons }) => lessons);

    const lesson = lessons.find(({ quiz }) => quiz?.id === Number(quizId));

    if (!lesson?.resources.some(({ completed_at }) => completed_at)) return;

    return lesson?.quiz;
  }, [guide, quizId]);

  if (!quiz) return <NotFound />;

  return (
    <Stack spacing={8}>
      <Stack
        p={{
          base: 4,
          md: 8,
        }}
        borderBottom="1px solid"
        textAlign="center"
      >
        <Heading color={isBaNorte_?'black':'brand'} size="md">{quiz.name}</Heading>
      </Stack>
      <Quiz quiz={quiz} />;
    </Stack>
  );
}

function NotFound() {
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });

  return (
    <Stack h="50vh" align="center" justify="center">
      <Heading>{t('not_found.title')}</Heading>
      <Text>{t('not_found.description')}</Text>
    </Stack>
  );
}

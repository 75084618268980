import { apiCall } from 'crud/api.crud';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAsterisk, FaCheck, FaExclamationCircle } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { CustomFields } from './CustomFields';
import { isBisblick } from 'utils/contants';
import { handleBlur, handleFocus } from 'utils/contants';

interface Schema {
  email: string;
  name: string;
  surname: string;
  bussiness_name: string;
  captcha: boolean;
  root?: string;
  customFields?: Array<{ id: number; value: string }>;
}

export default function Register() {
  const form = useForm<Schema>({
    defaultValues: {
      bussiness_name: '',
      email: '',
      name: '',
      surname: '',
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = form;
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (formData: Schema) => {
    try {
      const { data } = await apiCall('sendMailRegister', formData, 'POST');
      if (data.exist) {
        setError('root', {
          message: 'auth.email_error',
          type: 'success',
        });
      }
      setSuccess(true);
    } catch (error) {
      setError('root', {
        message: 'auth.msg_error',
        type: 'error',
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="mt-1 mb-4">{t('auth.create_account')}</h5>
        <h6>{t('auth.msg1')}</h6>
        <div className="d-flex mb-2">
          <FaAsterisk className="icon-required" />
          <span className="span span--error">{t('auth.required_fields')}</span>
        </div>
        <div className="form-group">
          <label htmlFor="email" className="required">
            {t('auth.email')}
          </label>
          <input
            type="email"
            className="form-control"
            {...register('email', {
              required: 'auth.required_fields',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
          />
          {errors.email?.message && <p className="text-danger">{t(errors.email.message)}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="name" className="required">
            {t('auth.name')}
          </label>
          <input
            className="form-control"
            {...register('name', {
              required: 'auth.required_fields',
            })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // required
          />
          {errors.name?.message && <p className="text-danger">{t(errors.name.message)}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="surname" className="required">
            {t('auth.surname')}
          </label>
          <input
            className="form-control"
            {...register('surname', {
              required: 'auth.required_fields',
            })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
          />
          {errors.surname?.message && <p className="text-danger">{t(errors.surname.message)}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="bussiness_name">{t('auth.organization')}</label>
          <input className="form-control" {...register('bussiness_name')} onFocus={handleFocus} onBlur={handleBlur} />
        </div>

        {isBisblick && <CustomFields />}

        <div className="form-group">
          <label htmlFor="bussiness_name" className="required">
            {t('auth.captcha')}
          </label>
          <Controller
            control={control}
            name="captcha"
            rules={{
              required: 'auth.required_fields',
            }}
            render={({ field }) => (
              <ReCAPTCHA
                sitekey="6Le9v-8eAAAAAPRQnMKKLydeeQnlJe2WRY4oTUJB"
                onChange={(token: string) => field.onChange(!!token.length)}
              />
            )}
          />
          {errors.captcha?.message && <p className="text-danger">{t(errors.captcha.message)}</p>}
        </div>

        {success && (
          <div className="alert alert-success" role="alert">
            <FaCheck className="mr-2" />
            {t('auth.success')}
          </div>
        )}

        {errors.root?.message && (
          <div className={`alert ${errors.root.type === 'error' ? 'alert-warning' : 'alert-danger'}`} role="alert">
            <FaExclamationCircle className="mr-4" size={16} />
            {t(errors.root.message)}
          </div>
        )}

        <div className="d-flex justify-content-around w-100">
          <Link to="/" className="button button--white">
            {t('auth.back_login')}
          </Link>
          <button type="submit" className="button" disabled={success}>
            {t(isSubmitting ? 'auth.validate' : 'auth.next')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
